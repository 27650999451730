import HeroBanner from "./components/HeroBanner";
import useResponsive from "./utils/responsive";

export default function PageError() {
    const { IsMobile } = useResponsive();
    return (
        <HeroBanner
            title='Oups'
            description='Le serveur a rencontré une erreur...'
            IsMobile={IsMobile}
            img='/assets/svg/404.svg'
            className='h-[80vh] xl:px-0'
            imgClassName='xl:col-[3_/_span_8] 2xl:col-[5_/_span_8]'
        />
    )
}