import HtmlDisplay from '../blocks/HtmlDisplay/HtmlDisplay';
import ArrowButton from '../blocks/ArrowButton/ArrowButton';
import { ButtonFront } from '../utils/types';

export interface HeroBannerFront {
	className?: string;
	title: string;
	description: string;
	img: string;
	imgClassName?: string;
	buttonOne?: ButtonFront;
	buttonTwo?: ButtonFront;
	hashtag?: string;
}

export default function HeroBanner({
	className,
	title,
	hashtag,
	description,
	buttonOne,
	buttonTwo,
	img,
	imgClassName,
	IsMobile,
}: HeroBannerFront & { IsMobile: boolean }) {
	return (
		<div
			className={`hero-banner ${
				className ?? ''
			} xl:grid xl:grid-cols-10 pt-[100px] px-[20px] xl:pb-0 pb-[50px] relative xl:h-[650px] md:bg-[#F4F9FA] xl:pl-[100px]`}
		>
			<div className="z-10 md:mr-16 flex flex-col gap-[32px] xl:h-[650px] mx-auto pb-[75px] xl:col-[1_/_span_6] 2xl:col-[2_/_span_4]">
				{hashtag && <p className="hashTagText mr-auto">{hashtag}</p>}
				<h1 className={`${IsMobile ? 'header-title-mobile' : 'header-title'}`}>{title}</h1>
				<HtmlDisplay className="header-text">{description}</HtmlDisplay>
				<div className="flex">
					{buttonOne && (
						<ArrowButton
							className="mr-4"
							type="secondary"
							label={buttonOne.label}
							url={buttonOne.url}
						/>
					)}
					{buttonTwo && (
						<ArrowButton
							type="primary"
							label={buttonTwo.label}
							url={buttonTwo.url}
						/>
					)}
				</div>
			</div>
			<div
				className={`${imgClassName} hidden xl:block xl:col-[7_/_span_4] 2xl:col-[6_/_span_5] w-100% absolute bottom-0`}
			>
				<img
					src={img}
					alt={img}
					className="md:block hidden max-h-[700px]"
				/>
			</div>
		</div>
	);
}
