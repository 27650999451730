import HtmlDisplay from '../../../blocks/HtmlDisplay/HtmlDisplay';
import ArrowButton from '../../../blocks/ArrowButton/ArrowButton';
import { ButtonFront } from '../../../utils/types';

export interface TextContentFront {
    text: string;
    button: ButtonFront;
}

export default function TextContent({ text, button }: TextContentFront) {
    return (
        <div className="flex flex-col gap-[32px]">
            <HtmlDisplay className="flex grow items-center">{text}</HtmlDisplay>
            {button?.label && button?.url && (
                <ArrowButton
                    type="primary"
                    className="lg:mr-auto"
                    url={button.url}
                    label={button.label}
                />
            )}
        </div>
    );
}
