import mapTextOnImage from "../Mappers/StrapiMappers/TextOnImage";
import mapTextBackground from "../Mappers/StrapiMappers/TextBackground";
import TextBackground from "../../components/TextBackground";
import TextQuote from "../../components/TextQuote";
import mapTextQuote from "../Mappers/StrapiMappers/TextQuote";
import TextInfo from "../../components/TextInfo";
import mapTextInfo from "../Mappers/StrapiMappers/TextInfo";
import TextOnImage from "../../components/TextOnImage";
import mapGallery from "../Mappers/StrapiMappers/Gallery";
import Gallery from "../../components/Gallery";
import mapHeroBanner from "../Mappers/StrapiMappers/HeroBanner";
import HeroBanner from "../../components/HeroBanner";
import CardListing from "../../components/CardListing";
import mapCardListing from "../Mappers/StrapiMappers/CardListing";
import ContentAndImage from "../../components/ContentAndImage/ContentAndImage";
import mapContentAndImage from "../Mappers/StrapiMappers/ContentAndImage";
import Text from '../../components/Text';
import mapText from "../Mappers/StrapiMappers/Text";

export function generateComponent(component: any, IsMobile: boolean) {
    switch (component.__component) {
        case 'content.list-text-and-image':
            return <ContentAndImage
                {...mapContentAndImage(
                    {
                        ...component,
                        contentType: 'list'
                    }
                )}
                IsMobile={IsMobile}
            />;
        case 'content.simple-text-and-image':
            return <ContentAndImage
                {...mapContentAndImage(
                    {
                        ...component,
                        contentType: 'text'
                    }
                )}
                IsMobile={IsMobile}
            />;
        case 'content.text-on-image':
            return <TextOnImage {...mapTextOnImage(component)} IsMobile={IsMobile} />;
        case 'content.text-background':
            return <TextBackground {...mapTextBackground(component)} IsMobile={IsMobile} />;
        case 'content.text-quote':
            return <TextQuote {...mapTextQuote(component)} IsMobile={IsMobile} />;
        case 'content.text-info':
            return <TextInfo {...mapTextInfo(component)} IsMobile={IsMobile} />;
        case 'content.gallery':
            return <Gallery {...mapGallery(component)} IsMobile={IsMobile} />;
        case 'content.hero-banner':
            return <HeroBanner {...mapHeroBanner(component)} IsMobile={IsMobile} />;
        case 'content.card-description':
            return <CardListing {...mapCardListing(component)} IsMobile={IsMobile} />;
        case 'content.text':
            return <Text {...mapText(component)} />;
        default:
            return null;
    }
}