import { getFullLogoUrl, mapButton } from '../../../utils/utils';
import { BASE_URL } from '../../../services/ApiService';
import { HeroBannerFront } from '../../../components/HeroBanner';
import { ButtonBack, LogoData } from '../../../utils/types';

export interface HeroBannerBack {
	title: string;
	description: string;
	image: LogoData;
	buttons: ButtonBack[];
	top_title: string;
}

export default function mapHeroBanner({
	title,
	description,
	image,
	buttons,
	top_title,
}: HeroBannerBack): HeroBannerFront {
	return {
		title,
		description,
		img: getFullLogoUrl(image, BASE_URL),
		...(buttons?.[0]
			? {
					buttonOne: mapButton(buttons[0]),
			  }
			: {}),
		...(buttons?.[1]
			? {
					buttonTwo: mapButton(buttons[1]),
			  }
			: {}),
		hashtag: top_title,
	};
}
