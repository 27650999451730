import TitleAndSubtitle from '../blocks/TitleAndSubtitle/TitleAndSubtitle';
import { generateUniqueId } from '../utils/utils';
import { A11y } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useRef } from 'react';

export interface CardData {
	id: number;
	rating: number;
	text: string;
	firstName: string;
	lastName: string;
	date: string;
	readTime: number;
	profilePicture: string;
}

export interface CardListingFront {
	cards: CardData[];
	amountToDisplay?: number;
	title?: string;
	subtitle?: string;
}

export default function CardListing({
	cards,
	amountToDisplay = 6,
	title,
	subtitle,
	IsMobile,
}: CardListingFront & { IsMobile: boolean }) {
	const swiperRef = useRef<SwiperClass>();

	const generateCard = (card: CardData, className?: string) => {
		return (
			<div
				key={generateUniqueId()}
				className={`${className} flex flex-col rounded-[10px] p-[30px] gap-[16px] shadow-md bg-white md:min-h-[300px] min-h-[350px]`}
			>
				<div className="flex place-content-between mb-4">
					<div className="flex">
						{Array.from({ length: card.rating }).map((_, starIndex) => (
							<img
								src="/assets/svg/star.svg"
								alt="star"
								key={generateUniqueId()}
								className="w-4 h-4"
							/>
						))}
					</div>
					<img
						src="/assets/svg/quotes.svg"
						alt="quote"
						className="w-8 h-8"
					/>
				</div>
				<p className="grow cardText">{card.text}</p>
				<div className="flex">
					<img
						src={card.profilePicture}
						alt={card.profilePicture}
						className="rounded-full w-8 h-8 object-cover mt-4"
					/>
					<div className="ml-4 text-xs mt-auto cardDetails">
						<p>
							{card.firstName} {card.lastName}
						</p>
						<p>{new Date(card.date).toDateString().replace('/', ' ')}</p>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="lg:bg-[#F4F9FA] py-10">
			<div className="flex flex-col max-w-[1280px] lg:mx-auto px-[22px]">
				<div className="flex justify-items-center mb-8">
					<TitleAndSubtitle
						title={title ?? ''}
						subtitle={subtitle ?? ''}
						IsMobile={IsMobile}
					/>
				</div>
				<div className="w-full lg:hidden gap-[15px]">
					<Swiper
						modules={[A11y]}
						spaceBetween={30}
						slidesPerView={1.3}
						onSwiper={(swiper: any) => {
							swiperRef.current = swiper;
						}}
					>
						{cards.slice(0, amountToDisplay).map((card) => (
							<SwiperSlide key={generateUniqueId()}>{generateCard(card)}</SwiperSlide>
						))}
					</Swiper>
				</div>
				<div className={`hidden lg:grid justify-between lg:grid-cols-3 gap-8`}>
					{cards.slice(0, amountToDisplay).map((card: CardData) => generateCard(card, 'w-auto'))}
				</div>
			</div>
		</div>
	);
}
