import HtmlDisplay from "../blocks/HtmlDisplay/HtmlDisplay";

export interface TextFront {
    title: string;
    text: string;
}

export default function Text({ title, text}: TextFront) {
    return (
        <div className="flex flex-col mx-auto gap-[60px] w-[60%]">
            <div className="text-4xl font-semibold">{title}</div>
            <HtmlDisplay className="text-lg">{text}</HtmlDisplay>
        </div>
    )
}