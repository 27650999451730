import './TitleAndSubtitle.css';

interface TitleAndSubtitleProps {
    title: string;
    titleColor?: string;
    subtitle: string;
}

export default function TitleAndSubtitle({ title, titleColor, subtitle, IsMobile }: TitleAndSubtitleProps & { IsMobile: boolean }) {
    return (
        <div className='title-and-subtitle flex items-center'>
            <img src='/assets/svg/titleIcon.svg' alt='titleIcon' className="w-12 h-12"/>
            <div className="ml-4 ">
                <p className={`text-xl ${titleColor ? titleColor : 'text-[--primary-color]'} ${IsMobile ? 'title-mobile' : 'title'}`}>{title}</p>
                <p className={`text-3xl font-medium ${IsMobile ? 'subtitle-mobile' : 'subtitle'}`}>{subtitle}</p>
            </div>
        </div>
    );
}
