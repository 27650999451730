import { buildUrl, getFullLogoUrl, mapButton } from '../../../utils/utils';
import { BASE_URL } from '../../../services/ApiService';
import { HeaderFront } from '../../../components/Menu';
import { ButtonBack, LogoData } from '../../../utils/types';

export interface HeaderBack {
	social_network: {
		icon: LogoData;
		external_URL: string;
	}[];
	logo: LogoData;
	menu: {
		data: {
			id: number;
			attributes: {
				title_page: string;
				url_page: string;
			};
		}[];
	};
	top_menu: {
		data: {
			id: number;
			attributes: {
				title_page: string;
				url_page: string;
			};
		}[];
	};
	button: ButtonBack;
	localizations: Localizations[];
}

export interface Localizations {
	id: number;
	attributes: {
		title_page: string;
		locale: string;
		url_page: string;
	};
}

export interface MenuList {
	text: string;
	slug: string;
}

export default function mapHeader({
	menu,
	top_menu,
	logo,
	social_network,
	button,
	localizations,
}: HeaderBack): HeaderFront {
	return {
		lists: {
			subMenu: top_menu.data.map((item) => ({
				text: item.attributes.title_page,
				slug: buildUrl(item.attributes.url_page),
			})),
			mainMenu: menu.data.map((item) => ({
				text: item.attributes.title_page,
				slug: buildUrl(item.attributes.url_page),
			})),
			button: mapButton(button),
			logoUrl: getFullLogoUrl(logo, BASE_URL),
			socialNetwork: social_network.map((item) => ({
				icon: getFullLogoUrl(item.icon, BASE_URL),
				external_URL: item.external_URL,
			})),
		},
		localizations,
	};
}
