import HtmlDisplay from "../blocks/HtmlDisplay/HtmlDisplay";
import React from "react";

export interface TextBackgroundFront {
    id: number;
    title: string;
    description: string;
}

export default function TextBackground({title, description, IsMobile }: TextBackgroundFront & { IsMobile: boolean }) {
    return (
        <div className="relative flex rounded-lg bg-[#C5161C] shadow-[0_15px_40px_rgba(0,0,0,0.25)] py-[80px] lg:py-[30px]
            lg:w-[90%] lg:mx-auto md:justify-center
            mx-[20px] px-[10px]">
            <img className={`absolute bottom-0 ${IsMobile ? 'left-[2%]' : 'left-[10%]'}`} src="/assets/svg/text-background.svg" alt="text-background" width={IsMobile ? '15%' : 130} />
            <img className={`absolute top-0 ${IsMobile ? 'right-[2%]' : 'right-[10%]'}`} src="/assets/svg/text-background-reverse.svg" alt="text-background" width={IsMobile ? '15%' : 130} />
            <div className="flex flex-col text-white w-full lg:w-3/5 gap-[30px]">
                <p className='text-4xl font-bold'>{title}</p>
                <div className='text-lg'>
                    <HtmlDisplay >{description}</HtmlDisplay>
                </div>
            </div>
        </div>
    );
}