import { useState } from 'react';
import { Localizations } from '../Core/Mappers/StrapiMappers/Header';
import { getLanguageFromPathname } from '../utils/utils';

const keyLocalStorageLanguage = 'kirchen_language';

export function getCurrentLanguage(): string {
    return 'en';
    // return (
    // getLanguageFromPathname() ??
    // localStorage.getItem(keyLocalStorageLanguage) ??
    // navigator.language.toLowerCase().slice(0, 2)
    // );
}

export interface LanguageSelectorProps {
    languages: string[];
    localizations: Localizations[];
}

export default function LanguageSelector({ languages, localizations }: LanguageSelectorProps) {
    const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLanguage);

    const handleLanguageChange = (event: { target: { value: any } }) => {
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage);
        localStorage.setItem(keyLocalStorageLanguage, newLanguage);
        const localization = localizations.find((item) => item?.attributes?.locale === newLanguage);
        const localizationUrl =
            localization?.attributes?.url_page === 'home' ? '' : `/${localization?.attributes?.url_page}`;
        window.location.replace(`${window.location.origin}/${newLanguage}${localizationUrl}`);
    };

    return (
        <div>
            <select
                className="lg:bg-white bg-[#F4F9FA]"
                id="language"
                value={selectedLanguage}
                onChange={handleLanguageChange}
            >
                {languages.map((language: string) => (
                    <option
                        key={language}
                        value={language}
                        className="text-bold"
                    >
                        {language.toUpperCase()}
                    </option>
                ))}
            </select>
        </div>
    );
}
