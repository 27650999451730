import { getCurrentLanguage } from '../components/LanguageSelector';
import mapHeader, { HeaderBack } from "../Core/Mappers/StrapiMappers/Header";
import mapFooter, { FooterBack } from "../Core/Mappers/StrapiMappers/Footer";
import { DataMail } from "../utils/types";
import { availableLanguages } from "../utils/utils";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL ?? '';
export const HOME_PAGE_NAME = process.env.REACT_APP_HOMEPAGE_NAME ?? '';

export function getPageName(): { locale: string, page: string } {
    const pathName = window.location.pathname;
    const indexSecondSlash = pathName.indexOf('/', 1);
    const currentLanguage = getCurrentLanguage();

    if (pathName === '/') {
        return {
            locale: currentLanguage,
            page: HOME_PAGE_NAME,
        }
    }
    if (indexSecondSlash === 3 || (indexSecondSlash === -1 && pathName.length < 5)) {
        const language = pathName.substr(1, 2).toLowerCase();
        if (availableLanguages.includes(language)) {
            if (pathName.length < 5) {
                return {
                    locale: language,
                    page: HOME_PAGE_NAME,
                }
            } else {
                return {
                    locale: language,
                    page: pathName.slice(4)
                }
            }
        } else {
            // No correct language
            // And no page provided
            if (pathName.length < 5) {
                return {
                    locale: currentLanguage,
                    page: HOME_PAGE_NAME
                }
            } else {
                // And page provided
                return {
                    locale: currentLanguage,
                    page: '404'
                }
            }
        }
    }
    return {
        locale: currentLanguage,
        page: pathName.slice(1)
    }
}

export async function getData(): Promise<any> {
    return Promise.allSettled([
        getHeader(),
        getFooter(),
        getPageContent()
    ])
    .then((data: any) => {
        const menuData = data?.[0]?.value?.attributes;
        const footerData = data?.[1]?.value?.attributes;
        const contentData = data?.[2]?.value?.[0]?.attributes;
        return {
            ...(menuData ? {
                menu: mapHeader({...menuData, localizations: contentData?.localizations?.data ?? [] })
            }: {}),
            ...(footerData ? {
                footer: mapFooter(footerData)
            }: {}),
            ...(contentData ? {
                content: contentData?.components
            }: {}),
        }
    });
}

const formatResponse = async <T = unknown>(response: Response): Promise<T> => {
    const { data } = await response.json();
    return data;
};

async function getPageContent() {
    const { locale, page } = getPageName();
    return fetchAndFormat(`${BASE_URL}/api/pages?filters[url_page]=${page}&populate=deep&locale=${locale}`);
}

async function getHeader(): Promise<{ attributes: HeaderBack } | null> {
    const locale = getCurrentLanguage().toLowerCase();
    return fetchAndFormat(`${BASE_URL}/api/header?populate=deep&locale=${locale}`);
}

export async function getAllPages(): Promise<{ attributes: { url_page: string }}[] | null> {
    return fetchAndFormat(`${BASE_URL}/api/pages`);
}

async function getFooter(): Promise<{ attributes: FooterBack } | null> {
    const locale = getCurrentLanguage();
    return fetchAndFormat(`${BASE_URL}/api/footer?populate=deep&locale=${locale}`);
}

export async function postMail({ fullName, email, company, phone, message }: DataMail): Promise<any> {
    const dataEmail = {
        to: [
            {
                name: `${fullName}`,
                email: process.env.REACT_APP_MAILING_TO_EMAIL,
            }
        ],
        from: {
            name: process.env.REACT_APP_MAILING_FROM_NAME,
            email: process.env.REACT_APP_MAILING_FROM_EMAIL,
        },
        subject: `[E-mail Website] - ${fullName} <${email}>`,
        body: {
            html: `<div>
                ${company && '<p>Company</p>'}
                ${company && '<p style="margin-left: 30px">' + company + '</p>'}
                ${phone && '<p>Phone</p>'}
                ${phone && '<p style="margin-left: 30px">' + phone + '</p>'}
                <p>${message}</p>
        </div>`}
    };

    try {
        if (process.env.REACT_APP_MAILING_API_BASE_URL !== undefined) {
            return fetch(process.env.REACT_APP_MAILING_API_BASE_URL, {
                method: "POST",
                body: JSON.stringify(dataEmail),
            });
        }
    } catch (e) {
        console.error(e);
    }
}

async function fetchAndFormat<T>(url: string) {
    try {
        const response = await fetch(url);
        return formatResponse<T>(response);
    } catch (e) {
        return null;
    }
}
