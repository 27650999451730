import { useEffect, useState } from 'react';
import Menu from './components/Menu';
import Footer from './components/Footer';
import { Data } from './utils/types';
import { getData } from './services/ApiService';
import useResponsive from './utils/responsive';
import { generateComponent } from "./Core/Generators/StrapiGenerator";
import { addLocaleInUrl, generateUniqueId } from "./utils/utils";
import PageNotFound from "./PageNotFound";
import ContactForm from "./components/ContactForm/ContactForm";
import PageError from "./PageError";
import PageMaintenance from "./PageMaintenance";
import Loader from "./blocks/Loader/Loader";

export default function Page() {
    const [data, setData] = useState<Data | undefined | null>(undefined);
    const {IsMobile} = useResponsive();

    useEffect(() => {
        addLocaleInUrl();
        getData().then(data => {
            if (data?.content || data?.menu || data?.footer) {
                setData(data);
            } else {
                setData(null);
            }
        });
    }, [window.location.pathname]);

    return (
        process.env.REACT_APP_SERVER_MAINTENANCE === '1' ? <PageMaintenance/>
            : data === undefined ? <Loader />
                : data === null ? <PageError />
                    : <div className='min-h-screen flex flex-col bg-[#FAFCFE]'>
                        {data.menu && <Menu lists={data.menu.lists} localizations={data.menu.localizations}/>}
                        {data.content ?
                            <div className={`flex flex-col mb-[100px] ${IsMobile ? 'page-mobile gap-[40px]' : 'gap-[100px]'}`}>
                                {data.content.map((content: any) => <div key={generateUniqueId()}>
                                    {generateComponent(content, IsMobile)}
                                </div>)}
                                {/* TODO - Contact Form Hardcoded in French */}
                                {(window.location.pathname === '/en/contact' || window.location.pathname === '/fr/contact') && <ContactForm/>}
                            </div> : <PageNotFound />}
                        {data.footer && <Footer data={data.footer}/>}
                    </div>
    );
}
