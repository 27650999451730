import { useMediaQuery } from 'react-responsive';

// Define the screen size breakpoints

const mobileBreakpoint = '(max-width: 768px)';
const desktopBreakpoint = '(min-width: 769px)';

// Export the isMobile and isDesktop functions

export default function useResponsive() {
    const IsMobile = useMediaQuery({ query: mobileBreakpoint });
    const IsDesktop = useMediaQuery({ query: desktopBreakpoint });
    const IsExclusiveTablet = useMediaQuery({
        minWidth: 768,
        maxWidth: 1200
    });

    return { IsMobile, IsDesktop, IsExclusiveTablet };
}