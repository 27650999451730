import { A11y } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import TitleAndSubtitle from '../blocks/TitleAndSubtitle/TitleAndSubtitle';
import { useRef, useState } from 'react';
import { generateUniqueId } from '../utils/utils';

export interface GalleryFront {
    id: number;
    imageUrls: string[];
    title: string;
    subtitle: string;
}

export default function Gallery({ imageUrls, title, subtitle, IsMobile } : GalleryFront & { IsMobile: boolean }) {
    const swiperRef = useRef<SwiperClass>();
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    return (
        <div className='max-w-[1280px] md:mx-auto px-[20px]'>
            <div className='flex flex-col md:flex-row md:place-items-center md:mb-[60px]'>
                <TitleAndSubtitle title={title} subtitle={subtitle} IsMobile={IsMobile} />
                <div className='flex ml-auto mt-auto mb-2 md:mb-0 gap-[15px]'>
                    <img
                        src='/assets/svg/left_gallery.svg'
                        alt='leftGallery'
                        className={`w-12 ${currentIndex === 0 ? 'opacity-70' : 'cursor-pointer'} hover:scale-110 transition-all duration-300'`}
                        onClick={() => {
                            swiperRef.current?.slidePrev();
                            if (swiperRef.current?.isBeginning) {
                                setCurrentIndex(0);
                            }
                        }}
                    />
                    <img
                        src='/assets/svg/right_gallery.svg'
                        alt='rightGallery'
                        className={`w-12 ${currentIndex === imageUrls.length - 1 ? 'opacity-70' : 'opacity-100 cursor-pointer'} hover:scale-110 transition-all duration-300`}
                        onClick={() => {
                            swiperRef.current?.slideNext();
                            if (swiperRef.current?.isEnd) {
                                setCurrentIndex(imageUrls.length - 1);
                            }
                        }}
                    />
                </div>
            </div>
            <Swiper
                modules={[A11y]}
                spaceBetween={30}
                slidesPerView={IsMobile ? 1 : 2}
                onSwiper={(swiper: any) => { swiperRef.current = swiper }}
            >
                {imageUrls?.map((imageUrl, index) => (
                    <SwiperSlide key={generateUniqueId()}>
                        <img src={imageUrl} alt={imageUrl} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
