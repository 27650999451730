import { TextQuoteFront } from "../../../components/TextQuote";

export interface TextQuoteBack {
    id: number;
    title: string;
    text: string;
    quote: {
        text: string;
        author: string;
    };
}

export default function mapTextQuote({ id, title, text, quote }: TextQuoteBack): TextQuoteFront {
    return {
        id,
        title,
        text,
        quote,
    }
}