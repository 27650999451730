import HtmlDisplay from "../blocks/HtmlDisplay/HtmlDisplay";
import React from "react";
import TitleAndSubtitle from "../blocks/TitleAndSubtitle/TitleAndSubtitle";

export interface TextOnImageFront {
    id: number;
    title: string;
    topTitle: string;
    description: string;
    image: string;
    imagePosition: 'left' | 'right';
}

export default function TextOnImage({
    title,
    topTitle,
    description,
    image,
    imagePosition,
    IsMobile
}: TextOnImageFront & { IsMobile: boolean }) {
    const mobile = <div className='flex flex-col p-[22px]'>
        <div className='rounded-[10px] p-[30px] flex flex-col gap-[16px] custom-shadow'>
            <TitleAndSubtitle IsMobile={IsMobile} title={topTitle} subtitle={title} />
            <HtmlDisplay className="mt-4">{description}</HtmlDisplay>
        </div>
        <img src={image} alt={image} />
    </div>
    return (
        IsMobile
            ? mobile
            : <div className={`flex items-center px-8 mx-auto md:h-[350px] max-w-[1280px] min-h-[300px]'}`}>
            <div className="grid grid-cols-9 items-center">
                     <div className={`flex flex-col gap-[15px] bg-[#fff] overflow-hidden
                                      row-start-1 ${imagePosition === 'right' ? 'col-start-1 col-end-7' : 'col-start-5 col-end-10'}
                     rounded-lg drop-shadow-xl p-[30px]`}>
                        <TitleAndSubtitle IsMobile={IsMobile} title={topTitle} subtitle={title} />
                        <HtmlDisplay className="mt-4">{description}</HtmlDisplay>
                    </div>
                    <img className={`max-h-[400px] row-start-1 ${imagePosition === 'right' ? 'col-start-5 col-end-10': 'col-start-1 col-end-6'}`} src={image} alt={image} />
                </div>
            </div>
    );
}