import { CardData, CardListingFront } from "../../../components/CardListing";
import { LogoData } from "../../../utils/types";
import { getFullLogoUrl } from "../../../utils/utils";
import { BASE_URL } from "../../../services/ApiService";

export interface CardListingBack {
    data: {
        id: number;
        text: string;
        firstName: string;
        lastName: string;
        date: string;
        readTime: number;
        rating: number;
        profile_picture: LogoData;
    }[];
    title: string;
    top_title: string;
}

export default function mapCardListing({ data, title, top_title }: CardListingBack): CardListingFront {
    return {
        subtitle: title,
        title: top_title,
        cards: data.map(item => ({
            id: item.id,
            text: item.text,
            firstName: item.firstName,
            lastName: item.lastName,
            date: item.date,
            readTime: item.readTime,
            rating: item.rating,
            profilePicture: getFullLogoUrl(item.profile_picture, BASE_URL)
        })) as CardData[],
    }
}