import { TextBackgroundFront } from "../../../components/TextBackground";

export interface TextBackgroundBack {
    id: number;
    title: string;
    description: string;
}

export default function mapTextBackground({ id, title, description}: TextBackgroundBack): TextBackgroundFront {
    return {
        id,
        title,
        description,
    }
}