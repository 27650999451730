import { getFullLogoUrl, mapButton } from '../../../utils/utils';
import { BASE_URL } from '../../../services/ApiService';
import { ButtonBack, LogoData } from '../../../utils/types';
import { ContentAndImageFront } from '../../../components/ContentAndImage/ContentAndImage';
import { TextContentFront } from '../../../components/ContentAndImage/CustomContent/TextContent';
import { ListContentFront } from '../../../components/ContentAndImage/CustomContent/ListContent';

export interface ContentAndImageBack {
	id: number;
	title: string;
	top_title: string;
	description: string;
	image: LogoData;
	image_position: 'left' | 'right';
	circle_image_color: 'red' | 'blue';
	button?: ButtonBack;
	list_items?: {
		text: string;
	}[];
}

export default function mapContentAndImage({
	id,
	title,
	top_title,
	description,
	image,
	button,
	image_position,
	circle_image_color,
	contentType,
	list_items,
}: ContentAndImageBack & { contentType: 'text' | 'list' }): ContentAndImageFront {
	let content: TextContentFront | ListContentFront = {} as TextContentFront | ListContentFront;
	if (contentType === 'text') {
		content = {
			text: description,
			...(button ? { button: mapButton(button) } : {}),
		} as TextContentFront;
	} else if (contentType === 'list') {
		content = {
			listItems: list_items ?? [],
			text: description,
		} as ListContentFront;
	}
	return {
		id: id,
		title,
		topTitle: top_title,
		content,
		image: getFullLogoUrl(image, BASE_URL),
		imagePosition: image_position,
		circleImageColor: circle_image_color,
		contentType,
	};
}
