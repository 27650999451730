import React from 'react';
import Input from '../blocks/Input/Input';
import { ListData } from '../Core/Mappers/StrapiMappers/Footer';
import CollapseMenu from '../blocks/CollapseMenu/CollapseMenu';
import { Link } from 'react-router-dom';
import { generateUniqueId } from '../utils/utils';
import ArrowButton from '../blocks/ArrowButton/ArrowButton';

export interface FooterFront {
	lists: ListData[][];
	socialMedia: {
		icon: string;
		external_URL: string;
	}[];
	copyright: string;
	logoUrl?: string;
}

export default function Footer({ data }: { data: FooterFront }) {
	const collapseData = data.lists
		.filter((item) => item?.[0]?.text && item?.[0]?.slug)
		.map((item, index) => ({
			content: <div className="text-lg font-semibold ">{item[0].text}</div>,
			children: item.slice(1).map((item) => (
				<Link
					key={generateUniqueId()}
					className="hover:underline"
					to={item.slug ?? ''}
				>
					{item.text}
				</Link>
			)),
		}));

	const copyrightElement = (
		<div className="copyright">
			© {new Date().getFullYear()} {data?.copyright}
		</div>
	);
	const bottomSocialMedia = (
		<div className="flex flex-row gap-[20px]">
			{data?.socialMedia?.map((item) => (
				<a
					key={generateUniqueId()}
					href={item.external_URL}
				>
					<img
						src={item.icon}
						alt={item.icon}
						className="cursor-pointer hover:scale-110 transition-all duration-300 w-[30px] h-[30px]"
					/>
				</a>
			))}
		</div>
	);

	return (
		<div className="bg-[#1B1B1B] text-white gap-[30px]">
			{/* Desktop */}
			<div
				className="hidden lg:flex flex-row gap-[10px] py-[50px]
                typo-b2 regular w-[75%] max-w-[95%] mx-auto py-[25px] justify-around"
			>
				{data.lists.map((list) => (
					<div
						key={generateUniqueId()}
						className="grid gap-[20px]"
					>
						<ul className="footer">
							{list.map((item, index) => (
								<li
									key={generateUniqueId()}
									className={`footer secondary-font-family hover:text-[#C5161C] cursor-pointer transition-all duration-300'
                                        ${index === 0 ? 'font-semibold text-xl mb-2' : ''}`}
								>
									<Link to={item.slug}>{item.text}</Link>
								</li>
							))}
						</ul>
					</div>
				))}
				<div className="grid gap-[20px]">
					<ul className="footer">
						<li className="secondary-font-family font-semibold text-xl mb-2">Newsletter</li>
						<li className="">
							<Input
								type="email"
								placeholder="Enter your email address"
							/>
						</li>
						<ArrowButton
							type="secondary"
							url="#"
							label="Je donne maintenant"
						/>
					</ul>
				</div>
			</div>
			<div className="hidden lg:flex bg-black justify-between items-center py-[16px] px-[100px]">
				{copyrightElement}
				<Link to="/">
					<img
						className="w-[200px] h-[50px]"
						src={data?.logoUrl}
						alt="footer logo"
					/>
				</Link>
				{bottomSocialMedia}
			</div>
			{/* Mobile */}
			<div className="lg:hidden mx-[22px] my-[30px] flex flex-col gap-[24px]">
				<CollapseMenu
					className="flex flex-col w-full justify-left gap-[24px] ml-0"
					menu={collapseData}
				/>
				<div className="flex flex-col gap-[16px]">
					<div className="font-semibold text-xl mb-2">Newsletter</div>
					<Input
						className="w-full rounded-[6px] px-[16px] py-[10px] m-auto"
						type="email"
						placeholder="Enter your email address"
					/>
					<ArrowButton
						type="secondary"
						url="#"
						label="Je donne maintenant"
					/>
				</div>

				<div className="flex flex-col gap-[32px] py-[16px]">
					<Link
						className="w-1/3"
						to="/"
					>
						<img
							src={data?.logoUrl}
							alt="footer logo"
						/>
					</Link>
					<div className="flex flex-row justify-between">
						{bottomSocialMedia}
						{copyrightElement}
					</div>
				</div>
			</div>
		</div>
	);
}
