import TitleAndSubtitle from '../../blocks/TitleAndSubtitle/TitleAndSubtitle';
import TextContent, { TextContentFront } from './CustomContent/TextContent';
import ListContent, { ListContentFront } from './CustomContent/ListContent';

export interface ContentAndImageFront {
	id: number;
	title: string;
	topTitle: string;
	content: TextContentFront | ListContentFront;
	image: string;
	imagePosition: 'left' | 'right';
	circleImageColor: 'red' | 'blue';
	contentType: 'text' | 'list';
}

export default function ContentAndImage({
	title,
	topTitle,
	content,
	image,
	imagePosition,
	circleImageColor,
	IsMobile,
	contentType,
}: ContentAndImageFront & { IsMobile: boolean }) {
	const height = contentType !== 'list' ? 'md:h-350px' : 'md:h-400px';
	const imageElement = (
		<div className={`flex items-center relative md:w-full ${height} float-right`}>
			<img
				className={`object-cover md:w-full ${height}`}
				src={image}
				alt={image}
			/>
			<img
				className={`absolute bottom-0 ${imagePosition === 'right' ? `right-0 scale-x-[-1]` : 'left-0'}`}
				src={`/assets/svg/${circleImageColor}_ellipse.svg`}
				alt=""
			/>
		</div>
	);

	const titleElement = (
		<TitleAndSubtitle
			IsMobile={IsMobile}
			title={topTitle}
			subtitle={title}
		/>
	);

	const contentElement = (
		<div className="flex flex-col gap-[32px]">
			{contentType === 'text' && <TextContent {...(content as TextContentFront)} />}
			{contentType === 'list' && <ListContent {...(content as ListContentFront)} />}
		</div>
	);

	const titleAndContentElement = (
		<div className="flex flex-col justify-between gap-[32px] w-1/2">
			{titleElement}
			{contentElement}
		</div>
	);

	return (
		<div className={`flex items-center px-[20px] mx-auto ${height} max-w-[1280px] min-h-[300px]'}`}>
			<div className="hidden md:flex md:justify-between md:items-center mx-auto gap-[60px]">
				{imagePosition === 'right' && (
					<>
						{titleAndContentElement}
						<div className="w-1/2">{imageElement}</div>
					</>
				)}
				{imagePosition === 'left' && (
					<>
						<div className="w-1/2">{imageElement}</div>
						{titleAndContentElement}
					</>
				)}
			</div>
			<div className="md:hidden flex flex-col w-full mx-auto gap-[30px]">
				{titleElement}
				{imageElement}
				{contentElement}
			</div>
		</div>
	);
}
