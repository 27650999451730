import { generateUniqueId } from '../../../utils/utils';
import { ArrowRight } from '../../../Icons/Icons';

export interface ListContentFront {
	listItems: {
		id: number;
		text: string;
	}[];
	text: string;
}

export default function ListContent({ listItems, text }: ListContentFront) {
	return (
		<>
			<ul className="flex flex-col gap-[16px] list-content p-0">
				{listItems.map((listItem, index) => (
					<li
						key={generateUniqueId()}
						className={`p-[20px] shadow-[0_4px_45px_0_rgba(0,0,0,0.15)] rounded-[10px] w-full flex items-center justify-between`}
					>
						<div className="flex justify-between w-full items-center gap-[20px]">
							<img
								src="/assets/svg/blueCheckMark.svg"
								alt="blueCheckMark"
							/>
							<div className="grow">{listItem.text}</div>
							<div className="w-[9px] h-[14px]">
								<ArrowRight className="w-[9px] h-[14px] text-[#17789B]" />
							</div>
						</div>
					</li>
				))}
			</ul>
			<div className="font-[Poppins]">{text}</div>
		</>
	);
}
