import { GalleryFront } from "../../../components/Gallery";
import { LogoData } from "../../../utils/types";
import { getFullLogoUrl } from "../../../utils/utils";
import { BASE_URL } from "../../../services/ApiService";

export interface GalleryBack {
    id: number;
    title: string;
    subtitle: string;
    images: { image: LogoData }[];
}

export default function mapGallery({ id, title, subtitle, images }: GalleryBack): GalleryFront {
    return {
        id,
        title,
        subtitle,
        imageUrls: images.map(item => getFullLogoUrl(item.image, BASE_URL)),
    }
}