import CollapseMenuElement from "./CollapseMenuElement";
import { generateUniqueId } from "../../utils/utils";

export interface CollapseElement {
    children: any[];
    content: any;
}

interface CollapseMenuProps {
    menu: CollapseElement[];
    className?: string;
}

export default function CollapseMenu({ className, menu }: CollapseMenuProps) {
    return (
        <div className={className}>
            {menu.map(parentElement =>
                <CollapseMenuElement
                    key={generateUniqueId()}
                    content={parentElement.content}
                    children={parentElement.children}
                />
            )}
        </div>
    )
}