import React, { useEffect, useState } from "react";
import { getAllPages, HOME_PAGE_NAME } from "./services/ApiService";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Page from "./Page";
import { generateUniqueId } from "./utils/utils";

export default function AppRouter() {
    const [pageUrls, setPageUrls] = useState<string[]>([]);

    useEffect(() => {
        getAllPages().then((data: { attributes: { url_page: string } }[] | null) => {
            if (data) {
                setPageUrls(data.map(item => item.attributes.url_page === HOME_PAGE_NAME ? '' : item.attributes.url_page))
            }
        });
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                {pageUrls.map((item) => <Route key={generateUniqueId()} path={item} Component={Page}/>)}
                <Route path='*' Component={Page} />
            </Routes>
        </BrowserRouter>
    );
}