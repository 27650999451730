import LanguageSelector from '../../components/LanguageSelector';
import { Localizations, MenuList } from '../../Core/Mappers/StrapiMappers/Header';
import { availableLanguages, generateUniqueId } from '../../utils/utils';
import ArrowButton from '../../blocks/ArrowButton/ArrowButton';
import { ButtonFront } from '../../utils/types';

export interface BurgerMenuProps {
    setVisible: (visible: boolean) => void;
    mainMenu: MenuList[];
    subMenu: MenuList[];
    socialNetworks: {
        icon: string;
        external_URL: string;
    }[];
    localizations: Localizations[];
    button: ButtonFront;
}

export default function BurgerMenu({
    setVisible,
    mainMenu,
    subMenu,
    socialNetworks,
    localizations,
    button,
}: BurgerMenuProps) {
    return (
        <div className="w-full max-h-[80vh] justify-between flex flex-col py-[17px] gap-[16px]">
            <img
                className="ml-auto mr-[22px] cursor-pointer"
                onClick={() => setVisible(false)}
                src="/assets/svg/close.svg"
                alt="close"
                width={15}
                height={15}
            />
            <div className="flex flex-col grow px-[22px] gap-[30px]">
                <div className="flex flex-col gap-[28px] secondary-font-family">
                    {mainMenu.map((item) => (
                        <a
                            key={generateUniqueId()}
                            href={item.slug}
                        >
                            {item.text}
                        </a>
                    ))}
                </div>
                <ArrowButton
                    className="justify-start"
                    type="secondary"
                    url={button.url}
                    label={button.label}
                />
                <div className="flex flex-col gap-[8px] text-sm text-[#333333]">
                    {subMenu.map((item) => (
                        <a
                            key={generateUniqueId()}
                            href={item.slug}
                        >
                            {item.text}
                        </a>
                    ))}
                </div>
            </div>
            <div className="relative flex flex-col justify-between bg-[#F4F9FA] w-full h-[150px] px-[22px] gap-[30px]">
                {/* <div className="mt-auto">
					<LanguageSelector
						languages={availableLanguages}
						localizations={localizations}
					/>
				</div> */}
                <div className="mb-auto flex flex-row">
                    {socialNetworks.map((item) => (
                        <a
                            key={generateUniqueId()}
                            className="cursor-pointer p-1"
                            href={item.external_URL}
                        >
                            <img
                                src={item.icon}
                                alt={item.icon}
                                width={25}
                                height={25}
                            />
                        </a>
                    ))}
                </div>
                <img
                    className="scale-x-[-1] absolute bottom-0 right-0"
                    src="/assets/svg/red_ellipse.svg"
                    alt="red_ellipse"
                    width={80}
                />
            </div>
        </div>
    );
}
