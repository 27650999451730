import { TextInfoFront } from "../../../components/TextInfo";

export interface TextInfoBack {
    id: number;
    title: string;
    info: string;
    description: string;
}

export default function mapTextInfo({ id, title, info, description }: TextInfoBack): TextInfoFront {
    return {
        id,
        title,
        info,
        description,
    }
}