import { getFullLogoUrl } from "../../../utils/utils";
import { BASE_URL } from "../../../services/ApiService";
import { TextOnImageFront } from "../../../components/TextOnImage";
import { LogoData } from "../../../utils/types";

export interface TextOnImageBack {
    id: number;
    title: string;
    top_title: string;
    description: string;
    image: LogoData;
    image_position: 'left' | 'right';
}

export default function mapTextOnImage({ id, title, top_title, description, image, image_position }: TextOnImageBack): TextOnImageFront {
    return {
        id,
        title,
        topTitle: top_title,
        description,
        image: getFullLogoUrl(image, BASE_URL),
        imagePosition: image_position,
    }
}