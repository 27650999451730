import { useCollapse } from "react-collapsed";
import { CollapseElement } from "./CollapseMenu";
import { generateUniqueId } from "../../utils/utils";

const CollapseMenuElement = ({ content, children }: CollapseElement) => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    return (
        <div className="flex flex-col">
            <div {...getToggleProps()}>
                <div className='flex flex-row gap-[5px]'>
                    {content}
                    <img src={`/assets/svg/${isExpanded ? 'arrow_up' : 'arrow_down'}.svg`} alt='arrow_down' width={20} height={20} />
                </div>
            </div>
            <div {...getCollapseProps()}>
                <div className="flex flex-col gap-[5px]">
                    {children.map(contentElement =>
                        <div key={generateUniqueId()}>{contentElement}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CollapseMenuElement;