import TitleAndSubtitle from '../../blocks/TitleAndSubtitle/TitleAndSubtitle';
import useResponsive from '../../utils/responsive';
import ArrowButton from '../../blocks/ArrowButton/ArrowButton';
import { FormEvent, useEffect } from 'react';
import { postMail } from '../../services/ApiService';
import toast, { Toaster } from 'react-hot-toast';
import PhoneNumberInput from './PhoneNumberInput/PhoneNumberInput';

export default function ContactForm() {
	const { IsMobile } = useResponsive();

	useEffect(() => {
		// Add reCaptcha
		const script = document.createElement('script');
		script.src = 'https://www.google.com/recaptcha/api.js?render=' + process.env.REACT_APP_RECAPTCHA_SITE_KEY;
		document.body.appendChild(script);
	}, []);

	async function handleSubmit(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();
		if (!process.env.REACT_APP_MAILING_API_BASE_URL) return;
		const formData = new FormData(e.target as HTMLFormElement);
		const data = Object.fromEntries(formData.entries());
		try {
			await postMail(data as any);
			toast.success('Votre message a bien été envoyé', { position: 'bottom-center' });
		} catch (e) {
			toast.error("Votre message n'a pas pu être envoyé", { position: 'bottom-center' });
		}
	}

	const generateInput = (
		name: string,
		label: string,
		placeholder: string = '',
		type = 'text',
		required: boolean = false,
	) => {
		return (
			<div
				className={`w-full flex items-start ${
					type === 'checkbox' ? 'gap-[8px] flex-row' : 'gap-[4px] flex-col'
				}`}
			>
				{type !== 'checkbox' && (
					<label
						className="secondary-font-family text-[#404040] text-lg"
						htmlFor={name}
					>
						{label}
					</label>
				)}
				{type === 'textarea' ? (
					<textarea
						required={required}
						className={`h-[150px] w-full lg:w-4/5 rounded-[6px] py-[10px] px-[16px] border-2`}
						style={{
							borderColor: 'var(--primary-color)',
						}}
						name={name}
						placeholder={placeholder}
					/>
				) : (
					<input
						required={required}
						className={`${
							type !== 'checkbox' ? 'h-[40px] w-full lg:w-4/5' : 'h-[28px]'
						} rounded-[6px] py-[10px] px-[16px] border-2 `}
						style={{
							borderColor: 'var(--primary-color)',
						}}
						type={type}
						placeholder={placeholder}
						name={name}
					/>
				)}
				{type === 'checkbox' && (
					<label
						className="secondary-font-family text-[#404040] text-lg"
						htmlFor={name}
					>
						{label}
					</label>
				)}
			</div>
		);
	};

	return (
		<div className="flex flex-col-reverse px-[22px] gap-[100px] lg:gap-0 lg:px-0 lg:flex-row justify-around mx-auto max-w-[1280px] lg:min-w-[800px]">
			<div className="flex flex-col w-full lg:w-2/5 items-start gap-[60px]">
				<TitleAndSubtitle
					subtitle="Formulaire"
					title=""
					IsMobile={IsMobile}
				/>
				<form
					onSubmit={handleSubmit}
					className="flex flex-col gap-[16px]"
				>
					<Toaster />
					{generateInput('fullName', 'Nom complet', '', 'text', true)}
					{generateInput('email', 'Email', '', 'text', true)}
					{generateInput('company', 'Société (facultatif)')}
					<PhoneNumberInput
						className="h-[40px] w-full lg:w-4/5 rounded-[6px] py-0 px-[8px] border-2"
						name="phone"
						label="Téléphone (facultatif)"
					/>
					{generateInput(
						'message',
						'Message',
						'Vous pouvez fournir une brève description de votre projet et poser toutes les questions que vous souhaitez.',
						'textarea',
						true,
					)}
					{generateInput(
						'checkbox',
						"J'accepte que Kim Kirchen collecte mes données afin de répondre à ma demande de contact. Politique de confidentialité",
						'',
						'checkbox',
						true,
					)}
					<div
						className="g-recaptcha"
						data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
						data-size="invisible"
					/>
					<ArrowButton
						submitButton
						className="mr-auto"
						type="secondary"
						label="Envoyer"
						url="#"
					/>
				</form>
			</div>
			<div className="flex flex-col gap-[32px] w-full lg:w-2/5 justify-start items-start">
				<div className="text-xl font-semibold underline">Adresse postale :</div>
				<div className="text-lg secondary-font-family">
					<div>Fondation Kim Kirchen</div>
					<div>56, rue des Romains</div>
					<div>L-2443 Senningerberg</div>
				</div>
				<div className="text-lg secondary-font-family">
					Email :&nbsp;
					<a
						href="mailto:info@fondationkimkirchen.lu"
						className="text-[var(--primary-color)] underline"
					>
						info@fondationkimkirchen.lu
					</a>
				</div>
			</div>
		</div>
	);
}
