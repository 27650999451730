import HtmlDisplay from "../blocks/HtmlDisplay/HtmlDisplay";
import React from "react";

export interface TextQuoteFront {
    id: number;
    title: string;
    text: string;
    quote: {
        text: string;
        author: string;
    };
}

export default function TextQuote({ title, text, quote }: TextQuoteFront & { IsMobile: boolean }) {
    return (
        <div className="relative mx-auto flex justify-center py-[30px] px-[20px] max-w-[1280px] min-h-[300px]">
            <div className="flex flex-col w-full gap-[30px]">
                <p className='text-4xl font-bold'>{title}</p>
                <HtmlDisplay className="text-lg">{text}</HtmlDisplay>
                <div className="flex flex-col gap-[10px] border-l-2 border-[#C5161C] pl-2">
                    <div className="text-lg">
                        {quote.text}
                    </div>
                    <div className="text-sm italic">
                        {quote.author}
                    </div>
                </div>
            </div>
        </div>
    );
}