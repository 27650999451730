import { useState } from 'react';
import LanguageSelector, { getCurrentLanguage } from './LanguageSelector';
import { Localizations, MenuList } from '../Core/Mappers/StrapiMappers/Header';
import BurgerMenu from '../blocks/BurgerMenu/BurgerMenu';
import { availableLanguages, generateUniqueId } from '../utils/utils';
import { Link } from 'react-router-dom';
import ArrowButton from '../blocks/ArrowButton/ArrowButton';
import { ButtonFront } from '../utils/types';

export interface HeaderFront {
    lists: {
        subMenu: MenuList[];
        mainMenu: MenuList[];
        socialNetwork: {
            icon: string;
            external_URL: string;
        }[];
        logoUrl?: string;
        button: ButtonFront;
    };
    localizations: Localizations[];
}

export default function TopMenu({ lists, localizations }: HeaderFront) {
    const isMenuSelected = (path: string) => {
        return path === decodeURIComponent(window.location.pathname).slice(0);
    };
    const [burgerVisible, setBurgerVisible] = useState<boolean>(false);
    return (
        <header>
            {/* Desktop */}
            <div
                className="lg:flex hidden py-8 flex-col md:flex-row items-center justify-between pr-4
                shadow-[0_4px_45px_0_rgba(0,0,0,0.15)] relative z-20"
            >
                <Link
                    to={`/`}
                    className="text-2xl ml-4"
                >
                    <img
                        className="w-[285px]"
                        src={lists?.logoUrl}
                        alt="Logo"
                    />
                </Link>
                <div>
                    <nav>
                        <ul className="menu flex space-x-4">
                            {lists.subMenu.map((item) => (
                                <li
                                    key={generateUniqueId()}
                                    className={`secondaryMenu cursor-pointer p-2 rounded text-sm ${
                                        isMenuSelected(item.slug) ? 'secondaryMenuSelected' : ''
                                    }`}
                                >
                                    <Link to={item.slug}>{item.text}</Link>
                                </li>
                            ))}
                            {lists.socialNetwork.map((item) => (
                                <li
                                    key={generateUniqueId()}
                                    className="cursor-pointer pt-1 text-sm"
                                >
                                    <Link to={item.external_URL}>
                                        <img
                                            className="hover:scale-110 transition-all duration-300"
                                            src={item.icon}
                                            alt={item.icon}
                                            width={25}
                                            height={25}
                                        />
                                    </Link>
                                </li>
                            ))}
                            {/* <li>
								<LanguageSelector
									languages={availableLanguages}
									localizations={localizations}
								/>
							</li> */}
                        </ul>
                    </nav>
                    <nav className="mt-4">
                        <ul className="flex space-x-4">
                            {lists.mainMenu.map((item, index) => (
                                <li
                                    key={generateUniqueId()}
                                    className={
                                        'flex items-center cursor-pointer p-2 rounded text-lg transition-all duration-300'
                                    }
                                >
                                    <Link
                                        to={item.slug}
                                        className={`mainMenu ${isMenuSelected(item.slug) ? 'menuSelected' : ''}`}
                                    >
                                        {item.text}
                                    </Link>
                                </li>
                            ))}
                            <ArrowButton
                                type="secondary"
                                url={lists.button.url}
                                label={lists.button.label}
                            />
                        </ul>
                    </nav>
                </div>
            </div>
            {/* Mobile */}
            <div className="lg:hidden w-full">
                {burgerVisible ? (
                    <BurgerMenu
                        mainMenu={[
                            ...[{ text: getCurrentLanguage() === 'fr' ? 'Accueil' : 'Home', slug: '/' }],
                            ...lists.mainMenu,
                        ]}
                        button={lists.button}
                        subMenu={lists.subMenu}
                        socialNetworks={lists.socialNetwork}
                        setVisible={setBurgerVisible}
                        localizations={localizations}
                    />
                ) : (
                    <div className="flex flex-row justify-between px-[20px] py-[12px]">
                        <Link to={`/`}>
                            <img
                                className="w-[285px]"
                                src={lists?.logoUrl}
                                alt="Logo"
                            />
                        </Link>
                        <img
                            className="cursor-pointer"
                            src="/assets/svg/burger_menu.svg"
                            alt="burger"
                            onClick={() => setBurgerVisible(true)}
                        />
                    </div>
                )}
            </div>
        </header>
    );
}
