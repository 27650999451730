import { TextFront } from "../../../components/Text";

export interface TextBack {
    id: number;
    title: string;
    text: string;
}

export default function mapText({ title, text }: TextBack): TextFront {
    return {
        title,
        text,
    }
}