import HtmlDisplay from "../blocks/HtmlDisplay/HtmlDisplay";
import React from "react";

export interface TextInfoFront {
    id: number;
    title: string;
    info: string;
    description: string;
}

export default function TextInfo({title, info, description }: TextInfoFront & { IsMobile: boolean }) {
    return (
        <div className="relative flex justify-center py-[30px] px-[20px] md:p-0">
            <div className="flex flex-col lg:w-3/5 w-full gap-[30px]">
                <p className='text-xl font-bold'>{title}</p>
                <HtmlDisplay className="text-lg rounded bg-[#8BBBCD]/[0.2] p-[15px]">{info}</HtmlDisplay>
                <HtmlDisplay>
                    {description}
                </HtmlDisplay>
            </div>
        </div>
    );
}