import { CSSProperties, useState } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './PhoneNumberInput.css';

interface PhoneNumberInputProps {
    name: string;
    required?: boolean;
    style?: CSSProperties;
    label: string;
    className?: string;
}

export default function PhoneNumberInput({ name, className, required = false, label }: PhoneNumberInputProps) {
    const [phone, setPhone] = useState('');
    return (
        <div>
            <label className='secondary-font-family text-[#404040] text-lg' htmlFor={name}>{label}</label>
            <PhoneInput
                required={required}
                name={name}
                className={`${className}`}
                style={{
                    borderColor: 'var(--primary-color)'
                }}
                defaultCountry="lu"
                value={phone}
                onChange={(phone) => setPhone(phone)}
            />
        </div>
    )
}