import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
import './ArrowButton.css';
import { ArrowRight } from "../../Icons/Icons";

// Primary uses the primary colors
// Secondary uses the secondary colors
// CSSProperties uses custom style
type Style = 'primary' | 'secondary' | CSSProperties;

export interface ArrowButtonProps {
    submitButton?: boolean;
    onClick?: () => void;
    type: Style;
    label: string;
    url: string;
    className?: string;
}

export default function ArrowButton({
    submitButton = false,
    onClick,
    type = 'primary',
    label,
    url,
    className,
}: ArrowButtonProps) {
    const getStyle = (style: Style): CSSProperties => {
        switch (style) {
            case 'primary':
                return {
                    color: 'var(--primary-color)',
                    backgroundColor: 'transparent',
                    borderColor: 'var(--primary-color)',
                }
            case 'secondary':
                return {
                    color: '#FFFFFF',
                    backgroundColor: 'var(--secondary-color)',
                    borderColor: 'var(--secondary-color)',
                }
            default:
                return style;
        }
    };
    return (
        onClick || submitButton ? <button
            type={submitButton ? "submit" : undefined}
            onClick={onClick}
            style={getStyle(type as Style)}
            className={`arrow-button custom-shadow ${className} my-auto p-4 rounded text-lg transition-all duration-300 hover:pl-6 hover:pr-2 border-2`}
        >
            <div className='arrow-button-label'>{label}</div>
            <ArrowRight />
        </button> : <Link
            onClick={onClick}
            to={url}
            style={getStyle(type as Style)}
            className={`arrow-button custom-shadow ${className} my-auto p-4 rounded text-lg transition-all duration-300 hover:pl-6 hover:pr-2 border-2`}
        >
            <div className='arrow-button-label'>{label}</div>
            <ArrowRight />
        </Link>
    );
}