import HeroBanner from './components/HeroBanner';
import useResponsive from './utils/responsive';
import { buildUrl } from './utils/utils';

export default function PageNotFound() {
	const { IsMobile } = useResponsive();
	return (
		<HeroBanner
			title="Page indisponible"
			hashtag="#error404"
			description="Nous n'avons pas pu accéder à votre demande"
			buttonOne={{
				label: "Retourner à la page d'accueil",
				url: '/',
			}}
			buttonTwo={{
				label: 'Contacter Kim Kirchen',
				url: buildUrl('contact'),
			}}
			IsMobile={IsMobile}
			img="/assets/svg/404.svg"
			className="xl:px-0"
			imgClassName="xl:col-[3_/_span_8] 2xl:col-[5_/_span_8]"
		/>
	);
}
