import { buildUrl, getFullLogoUrl } from "../../../utils/utils";
import { BASE_URL } from "../../../services/ApiService";
import { FooterFront } from "../../../components/Footer";
import { LogoData } from "../../../utils/types";

export interface FooterBack {
    footer_menu: {
        pages: {
            data: {
                id: number;
                attributes: {
                    title_page: string;
                    url_page: string;
                }
            }[]
        }
    }[];
    copyright: string;
    logo: LogoData;
    social_network: {
        icon: LogoData;
        external_URL: string;
    }[];
}

export interface ListData {
    slug: string;
    text: string
}

export default function mapFooter({ footer_menu, copyright, logo, social_network }: FooterBack): FooterFront {
    return {
        lists: footer_menu.map(item => item.pages.data.map(submenu => ({
            text: submenu.attributes.title_page,
            slug: buildUrl(submenu.attributes.url_page),
        }))),
        copyright,
        logoUrl: getFullLogoUrl(logo, BASE_URL),
        socialMedia: social_network.map(item => ({
            icon: getFullLogoUrl(item.icon, BASE_URL),
            external_URL: item.external_URL,
        }))
    }
}