import { ButtonBack, ButtonFront, LogoData } from './types';
import { getCurrentLanguage } from '../components/LanguageSelector';

export const availableLanguages = [/*'fr',*/ 'en'];

export function addLocaleInUrl() {
    if (!getLanguageFromPathname()) {
        window.location.pathname = `/${getCurrentLanguage()}${window.location.pathname}`;
    }
}

export function getLanguageFromPathname() {
    const pathName = window.location.pathname;
    const indexSecondSlash = pathName.indexOf('/', 1);

    // Check if we have a pattern like /fr or /fr/
    if (indexSecondSlash === 3 || (indexSecondSlash === -1 && pathName.length > 1 && pathName.length < 5)) {
        const language = pathName.slice(1, 3).toLowerCase();
        if (availableLanguages.includes(language)) {
            return language;
        }
    }
    return undefined;
}

export function getFullLogoUrl(logoData: LogoData, baseUrl: string): string {
    return `${baseUrl}${logoData?.data?.attributes?.url}`;
}

export function buildUrl(url: string): string {
    if (url.startsWith('http')) {
        return url;
    }
    return `/${getCurrentLanguage()}/${url}`;
}

export function mapButton(button: ButtonBack): ButtonFront {
    const url = button?.page?.data?.attributes?.url_page ?? button?.url ?? '';
    return {
        label: button?.label_button ?? '',
        url: buildUrl(url),
    };
}

let id = 0;

export function generateUniqueId(): string {
    return id++ + '';
}
